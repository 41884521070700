@media (max-width: $screen-lg) {
    // lg-md view
    .faq-block {
        padding: 50px 0 36px;
    }
}

@media (max-width: $screen-md) {
    // md-view
    .faq-block {
        padding: 39px 0 25px;
    }

    .faq { 
        .faq-holder { 
            .faq-list { 
                .faq-list-holder { 
                    .faq-block { 
                        padding: 21px 25px;
                        margin: 0 0 16px;
                        .question { 
                            font-size: 20px;
                        }

                        .answer { 
                            .inner-answer { 
                                .inner-answer-holder { 
                                    font-size: 12px;
                                    padding: 10px 0 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
