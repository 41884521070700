@media (max-width: $screen-lg) {
    // lg-md view
    .main-block { 
        .main-block-content { 
            .main-block-content-holder { 
                >.text {
                    >.text-holder {
                        padding: 0 0 9px;
                        .desc {

                        }
    
                        .heading {
                            width: 60%;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .main-block { 
        height: 550px;
        .main-block-content { 
            .main-block-content-holder { 
                >.text {
                    >.text-holder {
                        padding: 0 0 11px;
                        .desc {
                            font-size: 20px;
                            margin: 0 0 20px;
                        }

                        .heading {
                            width: 99%;
                        }
                    }
                }

                .company-points { 
                    bottom: 29px;
                    .company-points-holder { 
                        gap: 25px;
                        justify-content: space-between;
                        align-items: center;
                        .point { 
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}
