.company-points-block { 
    display: inline-block;
    width: 100%;
	.company-points-block-holder { 
        display: inline-block;
        width: 100%;
        padding: 49px 0 49px;
		.company-points { 
            display: inline-block;
            width: 100%;
			.company-points-holder { 
                display: inline-block;
                width: calc(100% + 10px);
                margin-left: -5px;
				.point { 
                    float: left;
                    width: 25%;
                    box-sizing: border-box;
                    padding: 0 5px;
                    margin: 0 0 10px;
					.point-holder { 
                        display: inline-block;
                        width: 100%;
                        background: white;
                        box-sizing: border-box;
                        padding: 25px;
						.icon { 
                            display: inline-block;
                            width: 100%;
							.icon-holder { 
                                float: left;
                                width: 50px;
                                height: 50px;
								svg { 
                                
								}
							}
						}

						.text { 
                            display: flex;
                            height: 76px;
                            align-items: flex-end;
							.text-holder { 
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
							}
						}
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/index/company_points";
@import "../../media/mobile/includes/index/company_points";
