.main-block { 
    display: inline-block;
    width: 100%;
    height: 700px;
    position: relative;
	.main-block-image { 
        display: inline-block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        background: $gray-base;
		img { 
            display: inline-block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 0.5;
		}
	}

	.main-block-content { 
        display: inline-block;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;
        .wrapper-inside {
            height: 100%;
        }

		.main-block-content-holder { 
            display: inline-block;
            width: 100%;
            height: 100%;
            position: relative;
			>.text { 
                display: flex;
                align-items: center;
                width: 100%;
                height: 100%;
                color: white;
				>.text-holder { 
                    display: inline-block;
                    width: 100%;
					.desc { 
                        display: inline-block;
                        width: 100%;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        margin: 0 0 21px;
					}

					.heading { 
                        display: inline-block;
                        width: 46%;
						h1, .h1 { 
                            line-height: 115%;
						}
					}
				}
			}

			.company-points { 
                display: inline-block;
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 31px;
                z-index: 1;
				.company-points-holder { 
                    display: flex;
                    gap: 50px;
					.point { 
                        color: white;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
						.point-holder { 
                            display: flex;
                            align-items: center;
							.icon { 
                                width: 45px;
                                height: 46px;
                                margin-right: 15px;
								.icon-holder { 
                                    width: 100%;
                                    height: 100%;
									svg { 
                                        width: 100%;
                                        height: 100%;
									}
								}
							}

							.text { 
                                width: auto;
								.text-holder { 

									br { 

									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/index/main_block";
@import "../../media/mobile/includes/index/main_block";
