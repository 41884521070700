@media (max-width: $screen-sm) {
    // sm-view
    .catalog-form-block {
        padding: 41px 0 41px;
        .catalog-form-block-holder {
            .common-heading {
                margin: 0 0 17px;
            }
        }
    }

    .catalog-form {
        min-height: auto;
        .catalog-form-holder {
            .image {
                display: none;
            }

            .form-block {
                width: 100%;
                .form-block-holder {
                    padding: 19px 20px 20px;
                    .heading {
                        h3, .h3 {
                            font-size: 20px;
                        }
                    }

                    .note {
                        padding: 0 3%;
                        font-size: 10px;
                        margin: 0 0 14px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
