.contacts-block {
    display: inline-block;
    width: 100%;
    padding: 50px 0 48px;
    .contacts-block-holder {
        display: inline-block;
        width: 100%;
    }
}

.contacts { 
    display: inline-block;
    width: 100%;
	.contacts-holder { 
        display: inline-block;
        width: 100%;
		.contacts-data { 
            display: inline-block;
            width: 100%;
			.contacts-data-holder { 
                display: flex;
                gap: 50px;
                width: 100%;
                margin: 0 0 29px;
				.data-line { 
                    float: left;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
					.data-line-holder { 
                        a {
                            display: inline-block;
                            color: $gray-base;
                            &:hover {
                                color: $brand-primary;
                            }
                        }

						.icon { 
                            float: left;
                            margin-right: 10px;
							svg { 
                                width: 18px;
                                height: 18px;
							}
						}

						.text { 
                            float: left;
						}   
					}
				}
			}
		}

		.map { 
            display: inline-block;
            width: 100%;
            height: 545px;
			.map-holder { 
                display: inline-block;
                width: 100%;
                height: 100%;
                border-radius: 10px;
                overflow: hidden;
				img { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
				}
			}
		}
	}
}

@import "../../media/tablet/includes/index/contacts";
@import "../../media/mobile/includes/index/contacts";
