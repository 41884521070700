@media (max-width: $screen-sm) {
    // sm-view
    .contacts-block {
        padding: 40px 0 38px;
    }

    .contacts { 
        .contacts-holder { 
            .contacts-data { 
                margin: 2px 0 0;
                .contacts-data-holder { 
                    gap: 20px;
                    align-items: flex-start;
                    flex-direction: column;
                    margin: 0 0 23px;
                    .data-line { 
                        font-size: 12px;
                        .data-line-holder { 
                            display: flex;
                            align-items: center;
                            .text {
                                max-width: 272px;
                            }
                        }
                    }
                }
            }

            .map {
                height: 350px;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
