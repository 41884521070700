@media (max-width: $screen-lg) {
    // lg-md view
    .about-block {
        padding: 49px 0 55px;
    }

    .about { 
        .about-holder { 
            .image {
                width: calc(100% - 450px);
                height: 496px;
            }

            .text { 
                width: 450px;
                .text-holder { 
                    .text-block { 
                        margin: 0 0 14px;
                        .action {
                            margin: 46px 0 0;
                            .btn {
                                padding: 12px 39px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .about-block {
        padding: 38px 0 35px;
    }

    .about { 
        .about-holder { 
            .image {
                width: 100%;
                height: 37vw;
                margin: 0 0 22px;
                .image-holder { 
                    a {

                    }
                    a.video-link {
                        &:after {
                            width: 45px;
                            height: 45px;
                            background-size: 13px 22px;
                        }
                    }
                }
            }

            .text {
                width: 100%;
                padding: 0;
                .text-holder { 
                    column-count: 2;
                    column-gap: 27px;
                    .text-block { 
                        display: inline;
                        h5, .h5 {
                            font-size: 16px;
                            margin: 0 0 16px;
                        }

                        ul, ol {
                            padding-left: 17px;
                        }

                        .action {
                            margin: 21px 0 0;
                            .btn {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}
