@media (max-width: $screen-sm) {
    // sm-view
    .about-block {
        padding: 38px 0 28px;
    }

    .about { 
        .about-holder { 
            .image {
                height: 91vw;
                margin: 0 0 20px;
            }

            .text {
                .text-holder { 
                    column-count: 1;
                    column-gap: 0;
                    .text-block { 
                        display: inline-block;
                        margin: 0 0 10px;
                        h5, .h5 {
                            font-size: 14px;
                            margin: 0 0 18px;
                        }

                        ol, ul {
                            li {
                                margin: 0 0 15px;
                            }
                        }

                        .action {
                            margin: 22px 0 0;
                            .btn { 
                                font-size: 12px;
                                width: 100%;
                                text-align: center;
                                padding: 11px 39px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
