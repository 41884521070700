@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .contacts-block {
        padding: 40px 0 40px;
    }
    
    .contacts { 
        .contacts-holder { 
            .contacts-data { 
                .contacts-data-holder { 
                    gap: 40px;
                    align-items: center;
                    .data-line { 
                        font-size: 14px;
                        .data-line-holder { 
                            display: flex;
                            align-items: center;
                            .text {
                                max-width: 272px;
                            }
                        }
                    }
                }
            }

            .map {
                height: 562px;
            }
        }
    }
}
