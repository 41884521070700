.about-block {
  display: inline-block;
  width: 100%;
  padding: 62px 0 55px;
}
.about-block .about-block-holder {
  display: inline-block;
  width: 100%;
}

.about {
  display: inline-block;
  width: 100%;
}
.about .about-holder {
  display: inline-block;
  width: 100%;
}
.about .about-holder .image {
  float: left;
  width: calc(100% - 493px);
  height: 477px;
}
.about .about-holder .image .image-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.about .about-holder .image .image-holder a {
  display: inline-block;
  width: 100%;
  height: 100%;
  background: #121214;
  border-radius: 10px;
  overflow: hidden;
}
.about .about-holder .image .image-holder a:hover img {
  opacity: 1;
  transition: all 300ms;
}
.about .about-holder .image .image-holder a img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.7;
  transition: all 300ms;
}
.about .about-holder .image .image-holder a.video-link {
  position: relative;
}
.about .about-holder .image .image-holder a.video-link:hover:after {
  background-color: #fad379;
  transition: all 300ms;
}
.about .about-holder .image .image-holder a.video-link:after {
  content: "";
  display: inline-block;
  background-image: url(../img/play.svg);
  background-position: 55% 50%;
  background-size: 15px 27px;
  background-repeat: no-repeat;
  background-clip: border-box;
  width: 80px;
  height: 80px;
  line-height: 80px;
  background-color: #F7B316;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 300ms;
}
.about .about-holder .text {
  float: left;
  width: 493px;
  box-sizing: border-box;
  padding-left: 30px;
}
.about .about-holder .text .text-holder {
  display: inline-block;
  width: 100%;
}
.about .about-holder .text .text-holder .text-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 33px;
}
.about .about-holder .text .text-holder .text-block p {
  color: #7A7A7A;
}
.about .about-holder .text .text-holder .text-block h5, .about .about-holder .text .text-holder .text-block .h5 {
  font-weight: 600;
  margin: 0 0 19px;
}
.about .about-holder .text .text-holder .text-block ul, .about .about-holder .text .text-holder .text-block ol {
  padding-left: 22px;
}
.about .about-holder .text .text-holder .text-block ul li, .about .about-holder .text .text-holder .text-block ol li {
  margin: 0 0 16px;
}
.about .about-holder .text .text-holder .text-block ul li::marker, .about .about-holder .text .text-holder .text-block ol li::marker {
  text-align: center !important;
  text-align-last: center !important;
}
.about .about-holder .text .text-holder .text-block .action {
  margin: 24px 0 0;
}
.about .about-holder .text .text-holder .text-block .action .btn {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 8px;
  padding: 16px 39px;
}

@media (max-width: 1499px) {
  .about-block {
    padding: 49px 0 55px;
  }
  .about .about-holder .image {
    width: calc(100% - 450px);
    height: 496px;
  }
  .about .about-holder .text {
    width: 450px;
  }
  .about .about-holder .text .text-holder .text-block {
    margin: 0 0 14px;
  }
  .about .about-holder .text .text-holder .text-block .action {
    margin: 46px 0 0;
  }
  .about .about-holder .text .text-holder .text-block .action .btn {
    padding: 12px 39px;
  }
}
@media (max-width: 1025px) {
  .about-block {
    padding: 38px 0 35px;
  }
  .about .about-holder .image {
    width: 100%;
    height: 37vw;
    margin: 0 0 22px;
  }
  .about .about-holder .image .image-holder a.video-link:after {
    width: 45px;
    height: 45px;
    background-size: 13px 22px;
  }
  .about .about-holder .text {
    width: 100%;
    padding: 0;
  }
  .about .about-holder .text .text-holder {
    column-count: 2;
    column-gap: 27px;
  }
  .about .about-holder .text .text-holder .text-block {
    display: inline;
  }
  .about .about-holder .text .text-holder .text-block h5, .about .about-holder .text .text-holder .text-block .h5 {
    font-size: 16px;
    margin: 0 0 16px;
  }
  .about .about-holder .text .text-holder .text-block ul, .about .about-holder .text .text-holder .text-block ol {
    padding-left: 17px;
  }
  .about .about-holder .text .text-holder .text-block .action {
    margin: 21px 0 0;
  }
  .about .about-holder .text .text-holder .text-block .action .btn {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .about-block {
    padding: 38px 0 28px;
  }
  .about .about-holder .image {
    height: 91vw;
    margin: 0 0 20px;
  }
  .about .about-holder .text .text-holder {
    column-count: 1;
    column-gap: 0;
  }
  .about .about-holder .text .text-holder .text-block {
    display: inline-block;
    margin: 0 0 10px;
  }
  .about .about-holder .text .text-holder .text-block h5, .about .about-holder .text .text-holder .text-block .h5 {
    font-size: 14px;
    margin: 0 0 18px;
  }
  .about .about-holder .text .text-holder .text-block ol li, .about .about-holder .text .text-holder .text-block ul li {
    margin: 0 0 15px;
  }
  .about .about-holder .text .text-holder .text-block .action {
    margin: 22px 0 0;
  }
  .about .about-holder .text .text-holder .text-block .action .btn {
    font-size: 12px;
    width: 100%;
    text-align: center;
    padding: 11px 39px;
  }
}
.catalog-form-block {
  display: inline-block;
  width: 100%;
  background: linear-gradient(180deg, #F3F3F3 0%, #EEE 100%);
  padding: 65px 0 24px;
}
.catalog-form-block .catalog-form-block-holder {
  display: inline-block;
  width: 100%;
}
.catalog-form-block .catalog-form-block-holder .common-heading {
  margin: 0 0 7px;
}

.catalog-form {
  display: inline-block;
  width: 100%;
  min-height: 571px;
  overflow: hidden;
}
.catalog-form .catalog-form-holder {
  display: inline-block;
  width: 100%;
  position: relative;
}
.catalog-form .catalog-form-holder .image {
  position: absolute;
  left: -30px;
  top: -100px;
  z-index: 1;
}
.catalog-form .catalog-form-holder .form-block {
  float: right;
  width: 607px;
  position: relative;
  z-index: 2;
}
.catalog-form .catalog-form-holder .form-block .form-block-holder {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 50px;
  background: white;
  border-radius: 10px;
}
.catalog-form .catalog-form-holder .form-block .form-block-holder .heading {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 0 0 14px;
}
.catalog-form .catalog-form-holder .form-block .form-block-holder .note {
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #7A7A7A;
  margin: 0 0 32px;
}

@media (max-width: 1499px) {
  .catalog-form {
    min-height: 532px;
  }
  .catalog-form .catalog-form-holder .image {
    left: -50px;
    top: -88px;
  }
  .catalog-form .catalog-form-holder .image .image-holder img {
    width: 760px;
    height: auto;
  }
  .catalog-form .catalog-form-holder .form-block {
    width: 457px;
  }
  .catalog-form .catalog-form-holder .form-block .form-block-holder {
    padding: 32px 40px 30px;
  }
  .catalog-form .catalog-form-holder .form-block .form-block-holder .heading {
    margin: 0 0 9px;
  }
  .catalog-form .catalog-form-holder .form-block .form-block-holder .note {
    line-height: 143%;
    margin: 0 0 27px;
  }
}
@media (max-width: 1025px) {
  .catalog-form-block {
    padding: 41px 0 24px;
  }
  .catalog-form {
    overflow: visible;
    min-height: 420px;
  }
  .catalog-form .catalog-form-holder .image {
    left: -31px;
    top: 20px;
  }
  .catalog-form .catalog-form-holder .image .image-holder img {
    width: 465px;
  }
  .catalog-form .catalog-form-holder .form-block {
    width: 393px;
  }
  .catalog-form .catalog-form-holder .form-block .form-block-holder {
    padding: 27px 30px 22px;
  }
  .catalog-form .catalog-form-holder .form-block .form-block-holder .heading h3, .catalog-form .catalog-form-holder .form-block .form-block-holder .heading .h3 {
    font-size: 22px;
  }
  .catalog-form .catalog-form-holder .form-block .form-block-holder .note {
    box-sizing: border-box;
    padding: 0 16%;
    margin: 0 0 15px;
  }
}
@media (max-width: 767px) {
  .catalog-form-block {
    padding: 41px 0 41px;
  }
  .catalog-form-block .catalog-form-block-holder .common-heading {
    margin: 0 0 17px;
  }
  .catalog-form {
    min-height: auto;
  }
  .catalog-form .catalog-form-holder .image {
    display: none;
  }
  .catalog-form .catalog-form-holder .form-block {
    width: 100%;
  }
  .catalog-form .catalog-form-holder .form-block .form-block-holder {
    padding: 19px 20px 20px;
  }
  .catalog-form .catalog-form-holder .form-block .form-block-holder .heading h3, .catalog-form .catalog-form-holder .form-block .form-block-holder .heading .h3 {
    font-size: 20px;
  }
  .catalog-form .catalog-form-holder .form-block .form-block-holder .note {
    padding: 0 3%;
    font-size: 10px;
    margin: 0 0 14px;
  }
}
.catalog-item .catalog-item-holder {
  display: inline-block;
  width: 100%;
}
.catalog-item .catalog-item-holder > .name {
  display: inline-block;
  width: 100%;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 10px;
}
.catalog-item .catalog-item-holder > .name a {
  color: #121214;
}
.catalog-item .catalog-item-holder > .name a:hover {
  color: #F7B316;
}
.catalog-item .catalog-item-holder .image {
  display: inline-block;
  width: 100%;
  height: 360px;
  margin: 0 0 10px;
}
.catalog-item .catalog-item-holder .image a {
  display: inline-block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 10px;
}
.catalog-item .catalog-item-holder .image a:hover img {
  transform: scale(1.1);
  transition: all 300ms;
}
.catalog-item .catalog-item-holder .image a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 300ms;
}
.catalog-item .catalog-item-holder .desc {
  display: flex;
  justify-content: space-between;
  width: calc(100% + 15px);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  height: 22px;
  overflow: hidden;
}
.catalog-item .catalog-item-holder .desc.small {
  font-size: 14px;
}
.catalog-item .catalog-item-holder .desc .data .price, .catalog-item .catalog-item-holder .desc .data .data-line {
  float: left;
  margin-right: 15px;
}
.catalog-item .catalog-item-holder .desc .data .price .name, .catalog-item .catalog-item-holder .desc .data .data-line .name {
  color: #7A7A7A;
  float: left;
  margin-right: 4px;
}
.catalog-item .catalog-item-holder .desc .data .price .value, .catalog-item .catalog-item-holder .desc .data .data-line .value {
  float: left;
}
@media (max-width: 1025px) {
  .catalog-item .catalog-item-holder > .name {
    font-size: 20px;
  }
  .catalog-item .catalog-item-holder .desc {
    font-size: 14px;
  }
  .catalog-item .catalog-item-holder .desc.small {
    font-size: 12px;
    height: 19px;
  }
}
@media (max-width: 767px) {
  .catalog-item .catalog-item-holder > .name {
    font-size: 16px;
    font-weight: 500;
  }
  .catalog-item .catalog-item-holder .image {
    height: 91vw;
  }
  .catalog-item .catalog-item-holder .desc {
    font-size: 12px;
  }
}
.catalog-list-block {
  display: inline-block;
  width: 100%;
  padding: 80px 0 19px;
}
.catalog-list-block.margin-down {
  padding: 0;
  margin: 0 0 65px;
}
.catalog-list-block .catalog-list-block-holder {
  display: inline-block;
  width: 100%;
}
.catalog-list-block .catalog-list-block-holder .display-options {
  display: inline-block;
  width: 100%;
  margin: 3px 0 31px;
}
.catalog-list-block .catalog-list-block-holder .display-options .display-options-holder {
  display: inline-block;
  width: 100%;
}
.catalog-list-block .catalog-list-block-holder .display-options .display-options-holder .option {
  float: left;
  font-size: 16px;
}
.catalog-list-block .catalog-list-block-holder .display-options .display-options-holder .option .name {
  float: left;
  margin-right: 16px;
}
.catalog-list-block .catalog-list-block-holder .display-options .display-options-holder .option .value {
  float: left;
}
.catalog-list-block .catalog-list-block-holder .display-options .display-options-holder .option .value .sort {
  float: left;
  margin-right: 11px;
}
.catalog-list-block .catalog-list-block-holder .display-options .display-options-holder .option .value .sort.active a {
  font-weight: 600;
  color: #121214;
}
.catalog-list-block .catalog-list-block-holder .display-options .display-options-holder .option .value .sort.desc a .icon svg {
  transform: rotate(180deg);
  transition: all 300ms;
}
.catalog-list-block .catalog-list-block-holder .display-options .display-options-holder .option .value .sort a {
  display: inline-block;
  position: relative;
  padding-right: 11px;
}
.catalog-list-block .catalog-list-block-holder .display-options .display-options-holder .option .value .sort a .icon {
  position: absolute;
  right: 0;
  top: 6px;
}
.catalog-list-block .catalog-list-block-holder .display-options .display-options-holder .option .value .sort a .icon svg {
  width: 6px;
  height: 4px;
  transition: all 300ms;
}

.catalog-list {
  display: inline-block;
  width: 100%;
}
.catalog-list .catalog-list-holder {
  display: inline-block;
  width: calc(100% + 20px);
  margin-left: -10px;
}
.catalog-list .catalog-list-holder .catalog-item {
  float: left;
  width: 50%;
  box-sizing: border-box;
  padding: 0 10px;
  margin: 0 0 28px;
}

@media (max-width: 1025px) {
  .catalog-list-block {
    padding: 60px 0 9px;
  }
  .catalog-list-block.margin-down {
    padding: 0;
    margin: 0 0 49px;
  }
  .catalog-list-block .catalog-list-block-holder .display-options .display-options-holder .option {
    font-size: 14px;
  }
  .catalog-list .catalog-list-holder {
    width: calc(100% + 10px);
    margin-left: -5px;
  }
  .catalog-list .catalog-list-holder .catalog-item {
    padding: 0 5px;
    margin: 0 0 21px;
  }
}
@media (max-width: 767px) {
  .catalog-list-block {
    padding: 41px 0 40px;
  }
  .catalog-list-block.margin-down {
    padding: 0;
    margin: 0 0 73px;
  }
  .catalog-list-block .catalog-list-block-holder .display-options {
    margin: 3px 0 26px;
  }
  .catalog-list-block .catalog-list-block-holder .display-options .display-options-holder .option {
    font-size: 12px;
  }
  .catalog-list .catalog-list-holder {
    width: 100%;
    margin: 0;
  }
  .catalog-list .catalog-list-holder .catalog-item {
    width: 100%;
    padding: 0;
    margin: 0 0 23px;
  }
  .catalog-list .catalog-list-holder .catalog-item:last-of-type {
    margin: 0;
  }
}
.common-heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 30px;
}
.common-heading .common-heading-holder {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  border-bottom: 1px solid #EDEDED;
  padding: 0 0 20px;
}
.common-heading .common-heading-holder .text h1, .common-heading .common-heading-holder .text .h1 {
  margin: 0;
}
.common-heading .common-heading-holder .action {
  padding: 0 0 3px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.common-heading .common-heading-holder .action a:hover {
  color: #F7B316;
}
.common-heading .common-heading-holder .action a .text {
  float: left;
}
.common-heading .common-heading-holder .action a .icon {
  float: left;
  width: 16px;
  height: 12px;
  margin-left: 10px;
  padding-top: 2px;
}
.common-heading .common-heading-holder .action a .icon svg {
  width: 100%;
  height: 100%;
}

.common-action {
  display: inline-block;
  width: 100%;
  margin: 13px 0 0;
}
.common-action .common-action-holder {
  display: flex;
  justify-content: center;
}
.common-action .common-action-holder .btn {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  display: flex;
  width: 290px;
  height: 40px;
  align-items: center;
  justify-content: center;
  color: #7A7A7A;
  border-color: #7A7A7A;
  background: white;
  border-radius: 8px;
  padding: 0;
}
.common-action .common-action-holder .btn .text {
  float: left;
}
.common-action .common-action-holder .btn .icon {
  float: left;
  margin-left: 10px;
}

@media (max-width: 1025px) {
  .common-heading .common-heading-holder .action {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .common-heading {
    margin: 0 0 25px;
  }
  .common-heading .common-heading-holder {
    padding: 0 0 9px;
  }
}
.company-points-block {
  display: inline-block;
  width: 100%;
}
.company-points-block .company-points-block-holder {
  display: inline-block;
  width: 100%;
  padding: 49px 0 49px;
}
.company-points-block .company-points-block-holder .company-points {
  display: inline-block;
  width: 100%;
}
.company-points-block .company-points-block-holder .company-points .company-points-holder {
  display: inline-block;
  width: calc(100% + 10px);
  margin-left: -5px;
}
.company-points-block .company-points-block-holder .company-points .company-points-holder .point {
  float: left;
  width: 25%;
  box-sizing: border-box;
  padding: 0 5px;
  margin: 0 0 10px;
}
.company-points-block .company-points-block-holder .company-points .company-points-holder .point .point-holder {
  display: inline-block;
  width: 100%;
  background: white;
  box-sizing: border-box;
  padding: 25px;
}
.company-points-block .company-points-block-holder .company-points .company-points-holder .point .point-holder .icon {
  display: inline-block;
  width: 100%;
}
.company-points-block .company-points-block-holder .company-points .company-points-holder .point .point-holder .icon .icon-holder {
  float: left;
  width: 50px;
  height: 50px;
}
.company-points-block .company-points-block-holder .company-points .company-points-holder .point .point-holder .text {
  display: flex;
  height: 76px;
  align-items: flex-end;
}
.company-points-block .company-points-block-holder .company-points .company-points-holder .point .point-holder .text .text-holder {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media (max-width: 1025px) {
  .company-points-block .company-points-block-holder {
    padding: 39px 0 31px;
  }
  .company-points-block .company-points-block-holder .company-points .company-points-holder .point {
    width: 50%;
    margin: 0 0 7px;
  }
  .company-points-block .company-points-block-holder .company-points .company-points-holder .point .point-holder {
    display: flex;
    align-items: center;
  }
  .company-points-block .company-points-block-holder .company-points .company-points-holder .point .point-holder .icon {
    width: auto;
  }
  .company-points-block .company-points-block-holder .company-points .company-points-holder .point .point-holder .icon .icon-holder {
    width: 40px;
    height: 40px;
  }
  .company-points-block .company-points-block-holder .company-points .company-points-holder .point .point-holder .icon .icon-holder svg {
    width: 100%;
    height: 100%;
  }
  .company-points-block .company-points-block-holder .company-points .company-points-holder .point .point-holder .text {
    width: calc(100% - 50px);
    height: auto;
    align-items: flex-start;
    box-sizing: border-box;
    padding-left: 15px;
  }
  .company-points-block .company-points-block-holder .company-points .company-points-holder .point .point-holder .text .text-holder {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .company-points-block .company-points-block-holder {
    padding: 40px 0 31px;
  }
  .company-points-block .company-points-block-holder .company-points .company-points-holder {
    width: 100%;
    margin-left: 0;
  }
  .company-points-block .company-points-block-holder .company-points .company-points-holder .point {
    width: 100%;
    padding: 0;
    margin: 0 0 8px;
  }
  .company-points-block .company-points-block-holder .company-points .company-points-holder .point .point-holder {
    padding: 11px 15px;
  }
  .company-points-block .company-points-block-holder .company-points .company-points-holder .point .point-holder .icon .icon-holder {
    width: 30px;
    height: 30px;
  }
  .company-points-block .company-points-block-holder .company-points .company-points-holder .point .point-holder .text .text-holder {
    font-size: 12px;
  }
}
.contacts-block {
  display: inline-block;
  width: 100%;
  padding: 50px 0 48px;
}
.contacts-block .contacts-block-holder {
  display: inline-block;
  width: 100%;
}

.contacts {
  display: inline-block;
  width: 100%;
}
.contacts .contacts-holder {
  display: inline-block;
  width: 100%;
}
.contacts .contacts-holder .contacts-data {
  display: inline-block;
  width: 100%;
}
.contacts .contacts-holder .contacts-data .contacts-data-holder {
  display: flex;
  gap: 50px;
  width: 100%;
  margin: 0 0 29px;
}
.contacts .contacts-holder .contacts-data .contacts-data-holder .data-line {
  float: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.contacts .contacts-holder .contacts-data .contacts-data-holder .data-line .data-line-holder a {
  display: inline-block;
  color: #121214;
}
.contacts .contacts-holder .contacts-data .contacts-data-holder .data-line .data-line-holder a:hover {
  color: #F7B316;
}
.contacts .contacts-holder .contacts-data .contacts-data-holder .data-line .data-line-holder .icon {
  float: left;
  margin-right: 10px;
}
.contacts .contacts-holder .contacts-data .contacts-data-holder .data-line .data-line-holder .icon svg {
  width: 18px;
  height: 18px;
}
.contacts .contacts-holder .contacts-data .contacts-data-holder .data-line .data-line-holder .text {
  float: left;
}
.contacts .contacts-holder .map {
  display: inline-block;
  width: 100%;
  height: 545px;
}
.contacts .contacts-holder .map .map-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.contacts .contacts-holder .map .map-holder img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1025px) {
  .contacts-block {
    padding: 40px 0 40px;
  }
  .contacts .contacts-holder .contacts-data .contacts-data-holder {
    gap: 40px;
    align-items: center;
  }
  .contacts .contacts-holder .contacts-data .contacts-data-holder .data-line {
    font-size: 14px;
  }
  .contacts .contacts-holder .contacts-data .contacts-data-holder .data-line .data-line-holder {
    display: flex;
    align-items: center;
  }
  .contacts .contacts-holder .contacts-data .contacts-data-holder .data-line .data-line-holder .text {
    max-width: 272px;
  }
  .contacts .contacts-holder .map {
    height: 562px;
  }
}
@media (max-width: 767px) {
  .contacts-block {
    padding: 40px 0 38px;
  }
  .contacts .contacts-holder .contacts-data {
    margin: 2px 0 0;
  }
  .contacts .contacts-holder .contacts-data .contacts-data-holder {
    gap: 20px;
    align-items: flex-start;
    flex-direction: column;
    margin: 0 0 23px;
  }
  .contacts .contacts-holder .contacts-data .contacts-data-holder .data-line {
    font-size: 12px;
  }
  .contacts .contacts-holder .contacts-data .contacts-data-holder .data-line .data-line-holder {
    display: flex;
    align-items: center;
  }
  .contacts .contacts-holder .contacts-data .contacts-data-holder .data-line .data-line-holder .text {
    max-width: 272px;
  }
  .contacts .contacts-holder .map {
    height: 350px;
  }
}
.faq-block {
  display: inline-block;
  width: 100%;
  padding: 59px 0 47px;
}
.faq-block .faq-block-holder {
  display: inline-block;
  width: 100%;
}
.faq-block .faq-block-holder .common-heading {
  margin: 0 0 9px;
}

.faq {
  display: inline-block;
  width: 100%;
}
.faq .faq-holder {
  display: inline-block;
  width: 100%;
}
.faq .faq-holder .faq-list {
  display: inline-block;
  width: 100%;
}
.faq .faq-holder .faq-list .faq-list-holder {
  display: inline-block;
  width: 100%;
}
.faq .faq-holder .faq-list .faq-list-holder .faq-block {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 25px;
  border-radius: 10px;
  background-color: white;
  margin: 0 0 15px;
}
.faq .faq-holder .faq-list .faq-list-holder .faq-block.open .question a .icon .open {
  opacity: 0;
  transition: all 300ms;
}
.faq .faq-holder .faq-list .faq-list-holder .faq-block .question {
  display: inline-block;
  width: 100%;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
}
.faq .faq-holder .faq-list .faq-list-holder .faq-block .question a {
  color: #121214;
}
.faq .faq-holder .faq-list .faq-list-holder .faq-block .question a .icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.faq .faq-holder .faq-list .faq-list-holder .faq-block .question a .icon .icon-holder {
  position: relative;
  width: 30px;
  height: 30px;
  background-color: #F7B316;
  border-radius: 50%;
  color: white;
}
.faq .faq-holder .faq-list .faq-list-holder .faq-block .question a .icon .icon-holder .open, .faq .faq-holder .faq-list .faq-list-holder .faq-block .question a .icon .icon-holder .close {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 11px;
  transition: all 300ms;
}
.faq .faq-holder .faq-list .faq-list-holder .faq-block .question a .icon .icon-holder .open svg, .faq .faq-holder .faq-list .faq-list-holder .faq-block .question a .icon .icon-holder .close svg {
  width: 100%;
  height: 100%;
}
.faq .faq-holder .faq-list .faq-list-holder .faq-block .question a:hover {
  color: #F7B316;
}
.faq .faq-holder .faq-list .faq-list-holder .faq-block .answer {
  float: left;
  width: 100%;
  height: 0;
  transition: all 300ms;
  overflow: hidden;
}
.faq .faq-holder .faq-list .faq-list-holder .faq-block .answer .inner-answer {
  display: inline-block;
  width: 100%;
}
.faq .faq-holder .faq-list .faq-list-holder .faq-block .answer .inner-answer .inner-answer-holder {
  padding: 9px 0 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: #7A7A7A;
}

@media (max-width: 1499px) {
  .faq-block {
    padding: 50px 0 36px;
  }
}
@media (max-width: 1025px) {
  .faq-block {
    padding: 39px 0 25px;
  }
  .faq .faq-holder .faq-list .faq-list-holder .faq-block {
    padding: 21px 25px;
    margin: 0 0 16px;
  }
  .faq .faq-holder .faq-list .faq-list-holder .faq-block .question {
    font-size: 20px;
  }
  .faq .faq-holder .faq-list .faq-list-holder .faq-block .answer .inner-answer .inner-answer-holder {
    font-size: 12px;
    padding: 10px 0 0;
  }
}
@media (max-width: 767px) {
  .faq-block {
    padding: 39px 0 30px;
  }
  .faq-block .faq-block-holder .common-heading {
    margin: 0 0 14px;
  }
  .faq .faq-holder .faq-list .faq-list-holder .faq-block {
    padding: 12px 40px 12px 15px;
    margin: 0 0 8px;
  }
  .faq .faq-holder .faq-list .faq-list-holder .faq-block .question {
    font-size: 16px;
  }
  .faq .faq-holder .faq-list .faq-list-holder .faq-block .question a .icon {
    right: -25px;
  }
  .faq .faq-holder .faq-list .faq-list-holder .faq-block .question a .icon .icon-holder {
    width: 24px;
    height: 24px;
  }
  .faq .faq-holder .faq-list .faq-list-holder .faq-block .answer .inner-answer .inner-answer-holder {
    font-size: 10px;
  }
}
.main-block {
  display: inline-block;
  width: 100%;
  height: 700px;
  position: relative;
}
.main-block .main-block-image {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background: #121214;
}
.main-block .main-block-image img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
}
.main-block .main-block-content {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
}
.main-block .main-block-content .wrapper-inside {
  height: 100%;
}
.main-block .main-block-content .main-block-content-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
}
.main-block .main-block-content .main-block-content-holder > .text {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  color: white;
}
.main-block .main-block-content .main-block-content-holder > .text > .text-holder {
  display: inline-block;
  width: 100%;
}
.main-block .main-block-content .main-block-content-holder > .text > .text-holder .desc {
  display: inline-block;
  width: 100%;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 21px;
}
.main-block .main-block-content .main-block-content-holder > .text > .text-holder .heading {
  display: inline-block;
  width: 46%;
}
.main-block .main-block-content .main-block-content-holder > .text > .text-holder .heading h1, .main-block .main-block-content .main-block-content-holder > .text > .text-holder .heading .h1 {
  line-height: 115%;
}
.main-block .main-block-content .main-block-content-holder .company-points {
  display: inline-block;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 31px;
  z-index: 1;
}
.main-block .main-block-content .main-block-content-holder .company-points .company-points-holder {
  display: flex;
  gap: 50px;
}
.main-block .main-block-content .main-block-content-holder .company-points .company-points-holder .point {
  color: white;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.main-block .main-block-content .main-block-content-holder .company-points .company-points-holder .point .point-holder {
  display: flex;
  align-items: center;
}
.main-block .main-block-content .main-block-content-holder .company-points .company-points-holder .point .point-holder .icon {
  width: 45px;
  height: 46px;
  margin-right: 15px;
}
.main-block .main-block-content .main-block-content-holder .company-points .company-points-holder .point .point-holder .icon .icon-holder {
  width: 100%;
  height: 100%;
}
.main-block .main-block-content .main-block-content-holder .company-points .company-points-holder .point .point-holder .icon .icon-holder svg {
  width: 100%;
  height: 100%;
}
.main-block .main-block-content .main-block-content-holder .company-points .company-points-holder .point .point-holder .text {
  width: auto;
}
@media (max-width: 1499px) {
  .main-block .main-block-content .main-block-content-holder > .text > .text-holder {
    padding: 0 0 9px;
  }
  .main-block .main-block-content .main-block-content-holder > .text > .text-holder .heading {
    width: 60%;
  }
}
@media (max-width: 1025px) {
  .main-block {
    height: 550px;
  }
  .main-block .main-block-content .main-block-content-holder > .text > .text-holder {
    padding: 0 0 11px;
  }
  .main-block .main-block-content .main-block-content-holder > .text > .text-holder .desc {
    font-size: 20px;
    margin: 0 0 20px;
  }
  .main-block .main-block-content .main-block-content-holder > .text > .text-holder .heading {
    width: 99%;
  }
  .main-block .main-block-content .main-block-content-holder .company-points {
    bottom: 29px;
  }
  .main-block .main-block-content .main-block-content-holder .company-points .company-points-holder {
    gap: 25px;
    justify-content: space-between;
    align-items: center;
  }
  .main-block .main-block-content .main-block-content-holder .company-points .company-points-holder .point {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .main-block .main-block-content .main-block-content-holder > .text {
    box-sizing: border-box;
    padding: 71px 0 163px;
  }
  .main-block .main-block-content .main-block-content-holder > .text > .text-holder {
    padding: 0 0 11px;
  }
  .main-block .main-block-content .main-block-content-holder > .text > .text-holder .desc {
    font-size: 16px;
    margin: 0 0 10px;
  }
  .main-block .main-block-content .main-block-content-holder > .text > .text-holder .heading {
    width: 99%;
  }
  .main-block .main-block-content .main-block-content-holder .company-points {
    bottom: 29px;
  }
  .main-block .main-block-content .main-block-content-holder .company-points .company-points-holder {
    gap: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .main-block .main-block-content .main-block-content-holder .company-points .company-points-holder .point {
    font-size: 14px;
    margin: 11px 0 0;
  }
  .main-block .main-block-content .main-block-content-holder .company-points .company-points-holder .point .point-holder .icon {
    width: 30px;
    height: 30px;
  }
}
.reviews-block {
  display: inline-block;
  width: 100%;
  padding: 30px 0 48px;
}
.reviews-block.margin-bottom {
  padding: 0;
  margin: 0 0 38px;
}
.reviews-block .reviews-block-holder {
  display: inline-block;
  width: 100%;
}
.reviews-block .common-actions {
  margin: 27px 0 0;
}

.reviews {
  display: inline-block;
  width: 100%;
}
.reviews .reviews-holder {
  display: inline-block;
  width: calc(100% + 10px);
  margin-left: -5px;
}
.reviews .reviews-holder.slick-slider .review {
  width: 100%;
}
.reviews .reviews-holder .review {
  float: left;
  width: 33.333%;
  box-sizing: border-box;
  padding: 0 5px;
  margin: 0 0 10px;
}

.review {
  width: 100%;
  box-sizing: border-box;
}
.review .review-holder {
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  background-color: #F9F9F9;
  box-sizing: border-box;
  padding: 25px;
}
.review .review-holder .author {
  display: flex;
  align-items: center;
  margin: 0 0 15px;
}
.review .review-holder .author .image {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  line-height: 65px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: white;
  background-color: #F7B316;
  overflow: hidden;
}
.review .review-holder .author .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.review .review-holder .author .text {
  width: calc(100% - 65px);
  box-sizing: border-box;
  padding-left: 15px;
}
.review .review-holder .author .text .name {
  width: 100%;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 5px;
}
.review .review-holder .author .text .stars {
  width: 100%;
}
.review .review-holder .author .text .stars .stars-rating {
  width: 100%;
  color: #EDEDED;
}
.review .review-holder .author .text .stars .stars-rating.stars-5 .star {
  color: #F7B316;
}
.review .review-holder .author .text .stars .stars-rating.stars-4 .star:nth-of-type(1), .review .review-holder .author .text .stars .stars-rating.stars-4 .star:nth-of-type(2), .review .review-holder .author .text .stars .stars-rating.stars-4 .star:nth-of-type(3), .review .review-holder .author .text .stars .stars-rating.stars-4 .star:nth-of-type(4) {
  color: #F7B316;
}
.review .review-holder .author .text .stars .stars-rating.stars-3 .star:nth-of-type(1), .review .review-holder .author .text .stars .stars-rating.stars-3 .star:nth-of-type(2), .review .review-holder .author .text .stars .stars-rating.stars-3 .star:nth-of-type(3) {
  color: #F7B316;
}
.review .review-holder .author .text .stars .stars-rating.stars-2 .star:nth-of-type(1), .review .review-holder .author .text .stars .stars-rating.stars-2 .star:nth-of-type(2) {
  color: #F7B316;
}
.review .review-holder .author .text .stars .stars-rating.stars-1 .star:nth-of-type(1) {
  color: #F7B316;
}
.review .review-holder .author .text .stars .stars-rating .star {
  float: left;
  margin-right: 3px;
}
.review .review-holder .author .text .stars .stars-rating .star svg {
  width: 18px;
  height: 18px;
}
.review .review-holder .review-body {
  display: inline-block;
  width: 100%;
}
.review .review-holder .review-body.with-video .review-body-holder .text {
  -webkit-line-clamp: 5;
  line-clamp: 5;
  height: 100px;
}
.review .review-holder .review-body.with-gallery .review-body-holder .text {
  -webkit-line-clamp: 10;
  line-clamp: 10;
  height: 200px;
}
.review .review-holder .review-body .review-body-holder {
  display: inline-block;
  width: 100%;
  height: 304px;
  overflow: hidden;
}
.review .review-holder .review-body .review-body-holder .text {
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  color: #7A7A7A;
  margin: 0 0 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 15;
  -webkit-box-orient: vertical;
  line-clamp: 15;
  box-orient: vertical;
}
.review .review-holder .review-body .review-body-holder .video {
  display: inline-block;
  width: 100%;
  height: 185px;
}
.review .review-holder .review-body .review-body-holder .video a {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  background-color: #121214;
}
.review .review-holder .review-body .review-body-holder .video a:hover:after {
  background-color: #fad379;
  transition: all 300ms;
}
.review .review-holder .review-body .review-body-holder .video a:hover img {
  opacity: 1;
  transition: all 300ms;
}
.review .review-holder .review-body .review-body-holder .video a:after {
  content: "";
  display: inline-block;
  background-image: url(../img/play.svg);
  background-position: 55% 50%;
  background-size: 12px 20px;
  background-repeat: no-repeat;
  background-clip: border-box;
  width: 45px;
  height: 45px;
  line-height: 45px;
  background-color: #F7B316;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 300ms;
}
.review .review-holder .review-body .review-body-holder .video a img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.7;
  transition: all 300ms;
}
.review .review-holder .review-body .review-body-holder .gallery {
  width: calc(100% + 10px);
  margin-left: -5px;
}
.review .review-holder .review-body .review-body-holder .gallery .image {
  width: 33.333%;
  float: left;
  box-sizing: border-box;
  padding: 0 5px;
}
.review .review-holder .review-body .review-body-holder .gallery .image a {
  display: inline-block;
  width: 100%;
  height: 85px;
  overflow: hidden;
  border-radius: 5px;
}
.review .review-holder .review-body .review-body-holder .gallery .image a:hover img {
  transform: scale(1.1);
  transition: all 300ms;
}
.review .review-holder .review-body .review-body-holder .gallery .image a img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 300ms;
}
.review .review-holder .review-body .action {
  display: inline-block;
  width: 100%;
  margin: 20px 0 0;
}
.review .review-holder .review-body .action a {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: #7A7A7A;
}
.review .review-holder .review-body .action a:hover {
  color: #F7B316;
}

@media (max-width: 1499px) {
  .reviews-block {
    padding: 30px 0 37px;
  }
  .review .review-holder .review-body.with-video .review-body-holder .text {
    -webkit-line-clamp: 7;
    line-clamp: 7;
    height: 140px;
  }
  .review .review-holder .review-body.with-gallery .review-body-holder .text {
    -webkit-line-clamp: 9;
    line-clamp: 9;
    height: 178px;
  }
  .review .review-holder .review-body .review-body-holder .video {
    height: 145px;
  }
  .review .review-holder .review-body .review-body-holder .gallery .image a {
    height: 106px;
  }
}
@media (max-width: 1025px) {
  .reviews-block {
    padding: 39px 0 28px;
    overflow: hidden;
  }
  .reviews-block.margin-bottom {
    margin: 0 0 28px;
  }
  .reviews.list .reviews-holder .review {
    width: 50%;
  }
  .reviews.list .reviews-holder .review .review-holder {
    width: 100%;
  }
  .reviews .slick-slider .slick-list {
    overflow: visible;
  }
  .reviews .reviews-holder .review {
    width: 335px;
  }
  .reviews .reviews-holder .review .review-holder {
    width: 325px;
  }
  .review .review-holder {
    padding: 20px;
  }
  .review .review-holder .author .image {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 20px;
  }
  .review .review-holder .author .text {
    width: calc(100% - 45px);
  }
  .review .review-holder .author .text .name {
    font-size: 14px;
  }
  .review .review-holder .review-body.with-video .review-body-holder .text {
    -webkit-line-clamp: 6;
    line-clamp: 6;
    height: 100px;
  }
  .review .review-holder .review-body.with-gallery .review-body-holder .text {
    -webkit-line-clamp: 10;
    line-clamp: 10;
    height: 175px;
  }
  .review .review-holder .review-body .review-body-holder {
    height: 265px;
  }
  .review .review-holder .review-body .review-body-holder .text {
    font-size: 12px;
    margin: 0 0 17px;
  }
  .review .review-holder .review-body .review-body-holder .video {
    height: 150px;
  }
  .review .review-holder .review-body .review-body-holder .gallery .image a {
    height: 72px;
  }
  .review .review-holder .review-body .action {
    margin: 13px 0 0;
  }
  .review .review-holder .review-body .action a {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .reviews-block .reviews-block-holder .common-action {
    margin: 23px 0 0;
  }
  .reviews.list .reviews-holder .review {
    width: 100%;
  }
  .reviews.list .reviews-holder .review .review-holder {
    width: 100%;
  }
  .reviews .reviews-holder .review {
    width: 270px;
  }
  .reviews .reviews-holder .review .review-holder {
    width: 260px;
  }
  .review .review-holder .author .text .name {
    margin: 0 0 4px;
  }
  .review .review-holder .author .text .stars .stars-rating .star svg {
    width: 12px;
    height: 12px;
  }
  .review .review-holder .review-body.with-video .review-body-holder .text {
    -webkit-line-clamp: 7;
    line-clamp: 7;
    height: 100px;
  }
  .review .review-holder .review-body.with-gallery .review-body-holder .text {
    -webkit-line-clamp: 14;
    line-clamp: 14;
    height: 200px;
  }
  .review .review-holder .review-body .review-body-holder {
    height: 270px;
  }
  .review .review-holder .review-body .review-body-holder .text {
    -webkit-line-clamp: 19;
    line-clamp: 19;
    font-size: 10px;
    margin: 0 0 14px;
  }
  .review .review-holder .review-body .review-body-holder .video {
    height: 155px;
  }
  .review .review-holder .review-body .review-body-holder .gallery .image a {
    height: 55px;
  }
  .review .review-holder .review-body .action a {
    font-size: 12px;
  }
}
.work-examples-block {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  padding: 50px 0 50px;
}
.work-examples-block .work-examples-block-holder {
  display: inline-block;
  width: 100%;
}

.works-slider .works-sldier-holder.slick-slider .slick-list {
  overflow: visible;
}

.work-example.big .work-example-holder {
  width: 843px;
}
.work-example .work-example-holder {
  display: inline-block;
  width: 500px;
  height: 577px;
  margin-right: 10px;
}
.work-example .work-example-holder a {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  border-radius: 10px;
  overflow: hidden;
}
.work-example .work-example-holder a:hover {
  color: #F7B316;
}
.work-example .work-example-holder a:hover .image img {
  transform: scale(1.1);
  opacity: 1;
  transition: all 300ms;
}
.work-example .work-example-holder a:hover .text .text-holder .date {
  opacity: 0;
  transition: all 300ms;
}
.work-example .work-example-holder a:hover .text .text-holder .desc {
  opacity: 0;
  transition: all 300ms;
}
.work-example .work-example-holder a .image {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  background: #121214;
}
.work-example .work-example-holder a .image img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.7;
  transition: all 300ms;
}
.work-example .work-example-holder a .text {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  box-sizing: border-box;
  padding: 20px;
}
.work-example .work-example-holder a .text .text-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
}
.work-example .work-example-holder a .text .text-holder .date {
  position: absolute;
  left: 0;
  top: 6px;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  transition: all 300ms;
  color: white;
}
.work-example .work-example-holder a .text .text-holder .desc {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  transition: all 300ms;
  color: white;
}
.work-example .work-example-holder a .text .text-holder .desc .desc-holder {
  width: calc(100% - 50px);
}
.work-example .work-example-holder a .text .text-holder .desc .desc-holder small {
  display: inline-block;
  font-size: 10px;
  line-height: 0;
  transform: translateY(-4px);
}
.work-example .work-example-holder a .text .text-holder .icon {
  position: absolute;
  right: 0;
  bottom: 0;
}
.work-example .work-example-holder a .text .text-holder .icon .icon-holder {
  width: 30px;
  height: 30px;
}
.work-example .work-example-holder a .text .text-holder .icon .icon-holder svg {
  width: 100%;
  height: 100%;
}

@media (max-width: 1499px) {
  .work-examples-block {
    padding: 40px 0 42px;
  }
  .work-example .work-example-holder {
    height: 497px;
  }
}
@media (max-width: 1025px) {
  .work-examples-block {
    padding: 40px 0 35px;
  }
  .work-example .work-example-holder {
    height: 381px;
  }
  .work-example .work-example-holder a {
    font-size: 14px;
  }
  .work-example .work-example-holder a .text .text-holder .date {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .work-examples-block {
    padding: 40px 0 40px;
  }
  .work-examples-block .work-examples-block-holder .common-action {
    margin: 22px 0 0;
  }
  .work-example.big .work-example-holder {
    width: 230px;
  }
  .work-example .work-example-holder {
    height: 331px;
    width: 260px;
  }
  .work-example .work-example-holder a {
    font-size: 12px;
  }
  .work-example .work-example-holder a .text {
    padding: 13px 15px;
  }
  .work-example .work-example-holder a .text .text-holder .date {
    font-size: 16px;
  }
  .work-example .work-example-holder a .text .text-holder .icon .icon-holder {
    width: 24px;
    height: 24px;
  }
}