@media (max-width: $screen-sm) {
    // sm-view
    .faq-block {
        padding: 39px 0 30px;
        .faq-block-holder {
            .common-heading {
                margin: 0 0 14px
            }
        }
    } 

    .faq { 
        .faq-holder { 
            .faq-list { 
                .faq-list-holder { 
                    .faq-block { 
                        padding: 12px 40px 12px 15px;
                        margin: 0 0 8px;
                        .question { 
                            font-size: 16px;
                            a {
                                .icon {
                                    right: -25px;
                                    .icon-holder {
                                        width: 24px;
                                        height: 24px;
                                    }
                                }
                            }
                        }

                        .answer { 
                            .inner-answer { 
                                .inner-answer-holder { 
                                    font-size: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
