@media (max-width: $screen-lg) {
    // lg-md view
    .catalog-form {
        min-height: 532px;
        .catalog-form-holder {
            .image {
                left: -50px;
                top: -88px;
                .image-holder {
                    img {
                        width: 760px;
                        height: auto;
                    }
                }
            }

            .form-block {
                width: 457px;
                .form-block-holder {
                    padding: 32px 40px 30px;
                    .heading {
                        margin: 0 0 9px;
                    }

                    .note {
                        line-height: 143%;
                        margin: 0 0 27px
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .catalog-form-block {
        padding: 41px 0 24px;
    }

    .catalog-form {
        overflow: visible;
        min-height: 420px;
        .catalog-form-holder {
            .image {
                left: -31px;
                top: 20px;
                .image-holder {
                    img {
                        width: 465px;
                    }
                }
            }

            .form-block {
                width: 393px;
                .form-block-holder {
                    padding: 27px 30px 22px;
                    .heading {
                        h3, .h3 {
                            font-size: 22px;
                        }
                    }

                    .note {
                        box-sizing: border-box;
                        padding: 0 16%;
                        margin: 0 0 15px;
                    }
                }
            }
        }
    }
}
