.catalog-form-block {
    display: inline-block;
    width: 100%;
    background: linear-gradient(180deg, #F3F3F3 0%, #EEE 100%);
    padding: 65px 0 24px;
    .catalog-form-block-holder {
        display: inline-block;
        width: 100%;
        .common-heading {
            margin: 0 0 7px;
        }
    }
}

.catalog-form {
    display: inline-block;
    width: 100%;
    min-height: 571px;
    overflow: hidden;
    .catalog-form-holder {
        display: inline-block;
        width: 100%;
        position: relative;
        .image {
            position: absolute;
            left: -30px;
            top: -100px;
            z-index: 1;
            .image-holder {

            }
        }

        .form-block {
            float: right;
            width: 607px;
            position: relative;
            z-index: 2;
            .form-block-holder {
                display: inline-block;
                width: 100%;
                box-sizing: border-box;
                padding: 50px;
                background: white;
                border-radius: 10px;
                .heading {
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    margin: 0 0 14px;
                    h3, .h3 {

                    }
                }

                .note {
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    color: $gray-dark;
                    margin: 0 0 32px;
                }
            }
        }
    }
}

@import "../../media/tablet/includes/index/catalog_form";
@import "../../media/mobile/includes/index/catalog_form";
