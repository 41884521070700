@media (max-width: $screen-sm) {
    // sm-view
    .main-block { 
        .main-block-content { 
            .main-block-content-holder { 
                >.text {
                    box-sizing: border-box;
                    padding: 71px 0 163px;
                    >.text-holder {
                        padding: 0 0 11px;
                        .desc {
                            font-size: 16px;
                            margin: 0 0 10px;
                        }

                        .heading {
                            width: 99%;
                        }
                    }
                }

                .company-points { 
                    bottom: 29px;
                    .company-points-holder { 
                        gap: 0;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        .point { 
                            font-size: 14px;
                            margin: 11px 0 0;
                            .point-holder { 
                                .icon { 
                                    width: 30px;
                                    height: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
