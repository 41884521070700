@media (max-width: $screen-sm) {
    // sm-view
    .company-points-block {
        .company-points-block-holder { 
            padding: 40px 0 31px;
            .company-points {
                .company-points-holder { 
                    width: 100%;
                    margin-left: 0;
                    .point { 
                        width: 100%;
                        padding: 0;
                        margin: 0 0 8px;
                        .point-holder { 
                            padding: 11px 15px;
                            .icon { 
                                .icon-holder {
                                    width: 30px;
                                    height: 30px;
                                 }

                            }

                            .text {
                                .text-holder {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
