.faq-block {
    display: inline-block;
    width: 100%;
    padding: 59px 0 47px;
    .faq-block-holder {
        display: inline-block;
        width: 100%;
        .common-heading {
            margin: 0 0 9px;
        }
    }
}

.faq { 
    display: inline-block;
    width: 100%;
	.faq-holder { 
        display: inline-block;
        width: 100%;
		.faq-list { 
            display: inline-block;
            width: 100%;
			.faq-list-holder { 
                display: inline-block;
                width: 100%;
				.faq-block { 
                    display: inline-block;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 20px 25px;
                    border-radius: 10px;
                    background-color: white;
                    margin: 0 0 15px;
                    &.open {
                        .question { 
                            a {
                                .icon {
                                    .open {
                                        opacity: 0;
                                        transition: all 300ms;
                                    }
                                }
                            }
                        }
                    }
					.question { 
                        display: inline-block;
                        width: 100%;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        position: relative;
						a { 
                            color: $gray-base;
                            .icon {
                                position: absolute;
                                right: 0;
                                top: 50%;
                                transform: translateY(-50%);
                                .icon-holder {
                                    position: relative;
                                    width: 30px;
                                    height: 30px;
                                    background-color: $brand-primary;
                                    border-radius: 50%;
                                    color: white;
                                    .open, .close {
                                        position: absolute;
                                        left: 50%;
                                        top: 50%;
                                        transform: translate(-50%, -50%);
                                        width: 12px;
                                        height: 11px;
                                        transition: all 300ms;
                                        svg {
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }

                                    .close {

                                    }
                                }
                            }
                            &:hover {
                                color: $brand-primary;
                            }
						}
					}

					.answer { 
                        float: left;
                        width: 100%;
                        height: 0;
                        transition: all 300ms;
                        overflow: hidden;
						.inner-answer { 
                            display: inline-block;
                            width: 100%;
							.inner-answer-holder { 
                                padding: 9px 0 0;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 140%; 
                                color: $gray-dark;
							}
						}
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/index/faq";
@import "../../media/mobile/includes/index/faq";
