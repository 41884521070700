@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .company-points-block {
        .company-points-block-holder { 
            padding: 39px 0 31px;
            .company-points { 
                .company-points-holder { 
                    .point { 
                        width: 50%;
                        margin: 0 0 7px;
                        .point-holder { 
                            display: flex;
                            align-items: center;
                            .icon {
                                width: auto;
                                .icon-holder {
                                    width: 40px;
                                    height: 40px;
                                    svg {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }

                            .text {
                                width: calc(100% - 50px);
                                height: auto;
                                align-items: flex-start;
                                box-sizing: border-box;
                                padding-left: 15px;
                                .text-holder { 
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                } 
            }
        }
    }
}
