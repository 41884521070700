.about-block {
    display: inline-block;
    width: 100%;
    padding: 62px 0 55px;
    .about-block-holder {
        display: inline-block;
        width: 100%;
    }
}

.about { 
    display: inline-block;
    width: 100%;
	.about-holder { 
        display: inline-block;
        width: 100%;
		.image { 
            float: left;
            width: calc(100% - 493px);
            height: 477px;
			.image-holder { 
                display: inline-block;
                width: 100%;
                height: 100%;
				a { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    background: $gray-base;
                    border-radius: 10px;
                    overflow: hidden;
                    &:hover {
                        img {
                            opacity: 1;
                            transition: all 300ms;
                        }
                    }
					img { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        opacity: 0.7;
                        transition: all 300ms;
					}
				}
                a.video-link {
                    position: relative;
                    &:hover {
                        &:after {
                            background-color: $brand-primary-accent;
                            transition: all 300ms;
                        }
                    }
                    &:after {
                        content: "";
                        display: inline-block;
                        background-image: url(../img/play.svg);
                        background-position: 55% 50%;
                        background-size: 15px 27px;
                        background-repeat: no-repeat;
                        background-clip: border-box;
                        width: 80px;
                        height: 80px;
                        line-height: 80px;
                        background-color: $brand-primary;
                        border-radius: 50%;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        transition: all 300ms;
                    }
                }
			}
		}

		.text { 
            float: left;
            width: 493px;
            box-sizing: border-box;
            padding-left: 30px;
			.text-holder { 
                display: inline-block;
                width: 100%;
				.text-block { 
                    display: inline-block;
                    width: 100%;
                    margin: 0 0 33px;
					p { 
                        color: $gray-dark;
					}

                    h5, .h5 {
                        font-weight: 600;
                        margin: 0 0 19px;
                    }

                    ul, ol {
                        padding-left: 22px;
                        li {
                            margin: 0 0 16px;
                            &::marker {
                                text-align: center !important;
                                text-align-last: center !important;
                            }
                        }

                    }

                    .action {
                        margin: 24px 0 0;
                        .btn {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            border-radius: 8px;
                            padding: 16px 39px;
                        }
                    }
				}
			}
		}
	}
}

@import "../../media/tablet/includes/index/about";
@import "../../media/mobile/includes/index/about";
